// actions/submit-popup-form.ts
"use server";

import { cookies } from "next/headers";
import prisma from "@/lib/prisma";
import { sesClient } from "@/lib/aws-ses";
import { SendEmailCommand } from "@aws-sdk/client-ses";
import { z } from "zod";

const FormSchema = z.object({
  contactOption: z.enum(["emailOnly", "emailAndPhone", "closed"]),
  email: z.string().email().or(z.literal("")),
  phoneNumber: z.union([z.string(), z.null(), z.undefined()]).optional(),
});

export async function submitPopupForm(formData: FormData) {
  const cookieStore = cookies();

  try {
    const token = formData.get("csrfToken");
    if (!token || token !== cookieStore.get("csrfToken")?.value) {
      throw new Error("Invalid CSRF token");
    }

    const rawData = {
      contactOption: formData.get("contactOption"),
      email: formData.get("email"),
      phoneNumber: formData.get("phoneNumber") || undefined,
    };

    const validatedData = FormSchema.parse(rawData);

    cookieStore.set("hasSeenPopup", "true", {
      maxAge: 31536000,
      path: "/",
      httpOnly: true,
      secure: true,
      sameSite: "strict",
    });

    if (validatedData.contactOption === "closed") {
      return { success: true };
    }

    if (validatedData.email) {
      await prisma.popupStayUpdatedFormSubmission.create({
        data: {
          email: validatedData.email,
          phoneNumber: validatedData.phoneNumber || null,
          contactOption: validatedData.contactOption,
          consentGiven: true,
        },
      });

      const emailParams = {
        Source: process.env.AWS_SES_SENDER_EMAIL,
        Destination: {
          ToAddresses: [
            process.env.FEEDBACK_EMAIL_1!,
            process.env.FEEDBACK_EMAIL_2!,
            process.env.FEEDBACK_EMAIL_3!,
          ],
        },
        Message: {
          Subject: {
            Data: `New Form Subscription: ${validatedData.contactOption}`,
            Charset: "UTF-8",
          },
          Body: {
            Html: {
              Data: `
                <html>
                  <body style="font-family: Arial, sans-serif;">
                    <h2 style="color: #333;">New Subscription Request</h2>
                    <div style="margin: 20px 0; background-color: #f9f9f9; padding: 15px; border-radius: 5px;">
                      <p><strong>Contact Option:</strong> ${validatedData.contactOption}</p>
                      <p><strong>Email:</strong> ${validatedData.email}</p>
                      ${validatedData.phoneNumber ? `<p><strong>Phone:</strong> ${validatedData.phoneNumber}</p>` : ""}
                    </div>
                  </body>
                </html>
              `,
              Charset: "UTF-8",
            },
          },
        },
      };

      await sesClient.send(new SendEmailCommand(emailParams));
    }

    return { success: true };
  } catch (error) {
    console.error("Form submission error:", error);
    return {
      success: false,
      error: error instanceof Error ? error.message : "Invalid form data",
    };
  }
}
