import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function absoluteUrl(path: string) {
  return `${process.env.NEXT_PUBLIC_APP_URL}${path}`;
}

export function getLogoCoverUniversityNames(
  university: string,
  urlUniversity: string | null,
  universuities: string[],
) {
  const keyLogoCover =
    university === "Any" && urlUniversity ? urlUniversity : university;

  const labelNameUniversity =
    university === "Any" && urlUniversity
      ? urlUniversity
      : university === "Any" && !urlUniversity
        ? `APERTO A ${universuities.length} UNIVERSITÀ`
        : university;

  return { keyLogoCover, labelNameUniversity };
}
