"use client";

import { useState, useEffect, useRef } from "react";
import { X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Checkbox } from "@/components/ui/checkbox";
import Image from "next/image";
import { useToast } from "@/components/ui/use-toast";
import { submitPopupForm } from "@/actions/submit-popup-form";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const phoneRegex = /^\+?[1-9]\d{1,14}$/;

export default function PopupStayUpdateForm() {
  const [isVisible, setIsVisible] = useState(false);
  const [contactOption, setContactOption] = useState("emailAndPhone");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [consent, setConsent] = useState(false);
  const [csrfToken, setCsrfToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { toast } = useToast();
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const token = crypto.randomUUID();
    document.cookie = `csrfToken=${token}; path=/; secure; samesite=strict`;
    setCsrfToken(token);

    const hasSeenPopup = document.cookie
      .split("; ")
      .find((row) => row.startsWith("hasSeenPopup="));
    if (!hasSeenPopup) {
      const timer = setTimeout(() => setIsVisible(true), 10000);
      return () => clearTimeout(timer);
    }
  }, []);

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!email || !emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (
      contactOption === "emailAndPhone" &&
      (!phoneNumber || !phoneRegex.test(phoneNumber))
    ) {
      newErrors.phone = "Please enter a valid phone number";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!consent || isSubmitting) return;
    if (!validateForm()) return;

    try {
      setIsSubmitting(true);
      const formData = new FormData(e.currentTarget);
      formData.append("csrfToken", csrfToken);

      const result = await submitPopupForm(formData);
      if (!result.success) throw new Error(result.error);

      closePopup();
      toast({
        title: "Success!",
        description: "Thank you for subscribing to our updates.",
      });
    } catch (error) {
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Submission failed",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const closePopup = () => {
    document.cookie = `hasSeenPopup=true; path=/; max-age=${10 * 24 * 60 * 60}`;
    setIsVisible(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      closePopup();
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        ref={popupRef}
        className="relative w-full max-w-md rounded-lg bg-white p-8 shadow-xl"
      >
        <button
          type="button"
          onClick={closePopup}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
          disabled={isSubmitting}
        >
          <X className="h-6 w-6" />
        </button>

        <div className="mb-6 flex flex-col items-center">
          <h2 className="text-2xl font-bold text-[#0c1235]">Stay Connected!</h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4" noValidate>
          <input type="hidden" name="csrfToken" value={csrfToken} />

          <RadioGroup
            name="contactOption"
            value={contactOption}
            onValueChange={setContactOption}
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="emailAndPhone" id="emailAndPhone" />
              <Label htmlFor="emailAndPhone" className="text-[#0c1235]">
                Email updates + 20-min call with our CEO
              </Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="emailOnly" id="emailOnly" />
              <Label htmlFor="emailOnly" className="text-[#0c1235]">
                Email updates only
              </Label>
            </div>
          </RadioGroup>

          <div>
            <Label htmlFor="email" className="text-[#0c1235]">
              Enter your email
            </Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
              placeholder="Your email"
              required
              maxLength={100}
              className={`border-[#398aff] focus:ring-[#398aff] ${errors.email ? "border-red-500" : ""}`}
            />
            {errors.email && (
              <span className="text-sm text-red-500">{errors.email}</span>
            )}
          </div>

          {contactOption === "emailAndPhone" && (
            <div>
              <Label htmlFor="phoneNumber" className="text-[#0c1235]">
                Enter your phone number
              </Label>
              <Input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={phoneNumber}
                onChange={(e) =>
                  setPhoneNumber(e.target.value.replace(/[^\d+]/g, ""))
                }
                placeholder="+1234567890"
                required
                maxLength={15}
                className={`border-[#398aff] focus:ring-[#398aff] ${errors.phone ? "border-red-500" : ""}`}
              />
              {errors.phone && (
                <span className="text-sm text-red-500">{errors.phone}</span>
              )}
            </div>
          )}

          <div className="flex items-center space-x-2">
            <Checkbox
              id="consent"
              checked={consent}
              onCheckedChange={(checked) => setConsent(checked as boolean)}
              className="border-[#398aff] text-[#398aff]"
              required
            />
            <Label htmlFor="consent" className="text-sm text-[#0c1235]">
              I consent to the use of my{" "}
              {contactOption === "emailAndPhone"
                ? "email and phone number"
                : "email"}{" "}
              for the purpose of receiving updates and communications from this
              platform.
            </Label>
          </div>

          <Button
            type="submit"
            disabled={!consent || isSubmitting}
            className="w-full bg-[#398aff] text-white hover:bg-[#33d4ff] disabled:cursor-not-allowed disabled:bg-gray-300"
          >
            {isSubmitting
              ? "Submitting..."
              : contactOption === "emailAndPhone"
                ? "Keep me updated and schedule call"
                : "Keep me updated"}
          </Button>
        </form>

        <p className="mt-4 text-center text-sm text-[#0c1235]">
          Want to register now?{" "}
          <a href="/sign-up" className="text-[#398aff] hover:underline">
            Click here
          </a>
        </p>
      </div>
    </div>
  );
}
